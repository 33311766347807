export const types = {

    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',

    GET_QUESTIONNAIRE_REQUEST: 'GET_QUESTIONNAIRE_REQUEST',
    GET_QUESTIONNAIRE_SUCCESS: 'GET_QUESTIONNAIRE_SUCCESS',
    GET_QUESTIONNAIRE_FAILURE: 'GET_QUESTIONNAIRE_FAILURE',
   
    GET_ORG_RESPONSES_REQUEST: 'GET_ORG_RESPONSES_REQUEST',
    GET_ORG_RESPONSES_SUCCESS: 'GET_ORG_RESPONSES_SUCCESS',
    GET_ORG_RESPONSES_FAILURE: 'GET_ORG_RESPONSES_FAILURE',

    GET_SPS_AND_RESPONSES_REQUEST: 'GET_SPS_AND_RESPONSES_REQUEST',
    GET_SPS_AND_RESPONSES_SUCCESS: 'GET_SPS_AND_RESPONSES_SUCCESS',
    GET_SPS_AND_RESPONSES_FAILURE: 'GET_SPS_AND_RESPONSES_FAILURE',

    SAVE_RESPONSE_REQUEST: 'SAVE_RESPONSE_REQUEST',
    SAVE_RESPONSE_SUCCESS: 'SAVE_RESPONSE_SUCCESS',
    SAVE_RESPONSE_FAILURE: 'SAVE_RESPONSE_FAILURE',
    RESTORE_RESPONSE_STATUS: 'RESTORE_RESPONSE_STATUS',

    BULK_SAVE_RESPONSE_REQUEST: 'BULK_SAVE_RESPONSE_REQUEST',
    BULK_SAVE_RESPONSE_SUCCESS: 'BULK_SAVE_RESPONSE_SUCCESS',
    BULK_SAVE_RESPONSE_FAILURE: 'BULK_SAVE_RESPONSE_FAILURE',

    GET_STATES_REQUEST: 'GET_STATES_REQUEST',
    GET_STATES_SUCCESS: 'GET_STATES_SUCCESS',
    GET_STATES_FAILURE: 'GET_STATES_FAILURE',

    UPDATE_STATE_REQUEST: 'UPDATE_STATE_REQUEST',
    UPDATE_STATE_SUCCESS: 'UPDATE_STATE_SUCCESS',
    UPDATE_STATE_FAILURE: 'UPDATE_STATE_FAILURE',

    GET_ORGANISATIONS_REQUEST: 'GET_ORGANISATIONS_REQUEST',
    GET_ORGANISATIONS_SUCCESS: 'GET_ORGANISATIONS_SUCCESS',
    GET_ORGANISATIONS_FAILURE: 'GET_ORGANISATIONS_FAILURE',

    GET_PROVISION_REQUEST: 'GET_PROVISION_REQUEST',
    GET_PROVISION_SUCCESS: 'GET_PROVISION_SUCCESS',
    GET_PROVISION_FAILURE: 'GET_PROVISION_FAILURE',

    SET_STATE_REQUEST: 'SET_STATE_REQUEST',

    SET_CURRENT_SECTION: 'SET_CURRENT_SECTION', // questionnaire section

    SET_ORG_REQUEST: 'SET_ORG_REQUEST',
}