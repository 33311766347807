import React, {useEffect, useState} from 'react'
import { Statistic, Row, Col, Typography, Card } from 'antd';
import BarChart from '../barChart/barChart'
import './kpi.summary.scss'
const { Title } = Typography;

/**
 * @description content
 * @param {layout} "vertical" | "horizontal" optional
 */

const StateSummaryItem = ({ data, layout }) => {

    const [values, setValues] = useState({});
    const [KPI_SCORE, setKPI_SCORE] = useState(0);

    useEffect(() => {
        if(Object.keys(data).length) {
            calculateKPIsValues()
        }
    }, [data])

    const calculateKPIsValues = () => {
        const SPs = data.survey_protocols
        const sortedData = groupDataByProperty(SPs, 'ydmsKpiYDMSKPIsId');

        let kpisListToSummarise = ['kpi_0', 'kpi_1', 'kpi_2', 'kpi_2.1', 'kpi_3', 'kpi_9', 'kpi_10', 'kpi_16'];

        const kpisDataToSummarise = kpisListToSummarise.map(kpi => {
            let kpiValueTemp = 0; 
            let kpiTotalWeight = 0;
            let kpiValue = 0;
            if(kpi === 'kpi_2.1') {
                if(!sortedData['kpi_2']) return { kpi, score: 0 };
                const KPI2_1Data = sortedData['kpi_2'].filter(SP => new RegExp("\\b" + 'the right of the fifth freedom of the air' + "\\b").test(SP.questionnaire_text.toLowerCase()));
                KPI2_1Data.forEach(SP => {
                    const response = SP.sp_response.questionnaire_response ? 1 : 0;
                    kpiValueTemp += response
                    kpiTotalWeight++
                });
                kpiValue = parseFloat((kpiValueTemp / kpiTotalWeight * 100).toFixed(2));
            } else {
                if(sortedData[kpi]) {
                    sortedData[kpi].forEach(SP => {
                        const response = SP.sp_response.questionnaire_response ? 1: 0;
                        kpiValueTemp += response * SP.weight
                        kpiTotalWeight += SP.weight
                    });
                    kpiValue = parseFloat(((kpiValueTemp / kpiTotalWeight) * 100).toFixed(2));
                }
            }
            return { kpi, score: kpiValue }
        });

        let _values = {};
        kpisDataToSummarise.forEach(item => {
            _values[item.kpi.toUpperCase()] = item.score;
        });
        setValues(_values);

        const globalScore = kpisDataToSummarise.reduce(( sum, { score }) => sum + score , 0);
        let KPI_SCORE = parseFloat((globalScore / kpisListToSummarise.length).toFixed(2));
        setKPI_SCORE(KPI_SCORE);
    }

     /**
     * Reshape array
     * @param {array} array 
     */
    const groupDataByProperty = (array, key) => {
        const result = array.reduce(function (r, a) {
            r[a[key]] = r[a[key]] || [];
            r[a[key]].push(a);
            return r;
        }, Object.create(null));
        return result
    }

    let BARDATA = []
    let keys = []

    keys = ["Percentage implementation", "Not implemented"]

    // Generate bar datas
    if(Object.keys(values).length) {
        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                const value = values[key];
                let color = '#43a047'
                if(value < 50) {
                    color = '#f44336'
                } else if(value < 75) {
                    color = '#fdd835'
                }
                BARDATA.push({
                    "country": `${key}`,
                    "Percentage implementation": value,
                    "Percentage implementationColor": color,
                    "Not implemented": 100-value,
                    "Not implementedColor": "#ddd"
                })
            }
        }
    }

    BARDATA = BARDATA.reverse()

    return (
        <div className="summary-item single-kpi-row">
           <Title level={5}>{data.full_name} - (Overall Score: <span className='text-primary'>{`${KPI_SCORE}%`}</span>)</Title>
           <div className="stats-wrapper">
                <p>KPIs Ranking</p>
                <div className="summary-item-column">
                    {BARDATA.length && <BarChart groupMode={"stacked"} layout={layout} legend={false} data={BARDATA} keys={keys} />}
                </div>
           </div>
        </div>
    )
}


export default StateSummaryItem;

