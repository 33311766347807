import {put, takeEvery} from 'redux-saga/effects'
import {getRequest, postRequestFormData} from '../../../../helpers/api'
import { types } from "../reducer/types";
import {config} from '../../../../helpers/constants'

const API_URL = `${config.API_URL}/api`

/**
 * @description get state Documents
 */
 function* getStateDocuments ({payload}) {
    try { 
        const {data} = yield getRequest(`${API_URL}/documents/states/${payload}`);
        yield put({type: types.GET_STATE_DOCUMENTS_SUCCESS, payload: data})
    } catch (error) {
        yield put({type: types.GET_STATE_DOCUMENTS_FAILURE, payload: error});
    }
}

/**
 * @description upload Documents
 * @param {{ payload: FormData}}
 */
 function* uploadDocument ({payload}) {
    try { 
        const {data} = yield postRequestFormData(`${API_URL}/documents`, payload);
        yield put({type: types.UPLOAD_DOCUMENTS_SUCCESS, payload: data});
        window.location.reload();
    } catch (error) {
        yield put({type: types.UPLOAD_DOCUMENTS_FAILURE, payload: error});
    }
}

export default function* ELibrarySaga() {
    yield takeEvery(types.GET_STATE_DOCUMENTS_REQUEST, getStateDocuments);
    yield takeEvery(types.UPLOAD_DOCUMENTS_REQUEST, uploadDocument);
}