import React from 'react';
import './short.report.template.scss';
import moment from "moment";
import StateSummaryItem from "../../../saatmDashboard/components/summary/state.summary.item";

const ShortReport = React.forwardRef(({ data, reportData, user, orgName }, ref) => {
    return (
        <div ref={ref} className="short-report-template">
            <div className='report-header' style={{ marginBottom: "30px" }}>
                <p>Country: <span className='text-bold'>{orgName}</span></p>
                <p>Date of report: {moment().format("DD MMM YYYY")}</p>
                <p>Author: {user?.state?.focal_person || "-"}</p>
                <h4 style={{ marginTop: "30px" }} className='text-primary mt-3 mb-4'>Audit Highlights:</h4>
            </div>

            {Object.keys(reportData.kpi_1).length > 0 &&
            <div className='kpi-audit'>
                <p>(i) <b className='text-bold'>KPI-1:</b> Promulgation of regulations relevant to YD.  KPI-1 is tracked with ({reportData.kpi_1?.totalSps || "*"}) indicators. The audit determined that {orgName} was compliant with {reportData.kpi_1?.compliant || "*"} indicators. The following indicators were non-compliant:</p>
                <ul className='unchecked'>
                    {reportData.kpi_1.nonCompliants.map(((item, index) => (
                        <li key={index}>{item}</li>
                    )))}
                </ul>
            </div>
            }

            {Object.keys(reportData.kpi_2).length > 0 &&
            <div className='kpi-audit'>
                <p>(iii) <b>KPI-2:</b> Granting of market access/BASAs to each SAATM State: SAATM currently has 35 States. The audit determined that {orgName} has negotiated {reportData.kpi_2?.airServiceAgreement} Bilateral Air Services Agreements with the 34 with SAATM member States. The audit verified six (7) indicators for each BASA (7*34=238 indicators). {reportData.kpi_2?.fullCompliants} BASAs were found to be fully compliant with YD, whilst {34 - reportData.kpi_2?.fullCompliants} were not. {orgName} currently does not have BASA with the following SAATM States ({reportData.kpi_2?.nonCompliantsStates.join("; ")}).</p>
                <p>The non-compliant BASAs are with the following States ({reportData.kpi_2?.nonCompliantsStates.join("; ")}). </p>
            </div>
            }

            {Object.keys(reportData.kpi_2_1).length > 0 &&
            <div className='kpi-audit'>
                <p>(iv) <b>KPI-2-1:</b> The right of fifth freedom of the air is granted in {reportData.kpi_2_1?.fifthFreedomAgreement} BASAs with {34 - reportData.kpi_2_1?.fifthFreedomAgreement} BASAs are restrictive.</p>
            </div>
            }

            {Object.keys(reportData.kpi_3).length > 0 &&
            <div className='kpi-audit'>
                <p>(iii) <b>KPI-03:</b> CAA economic oversight organisation. CCAA is autonomous civil aviation authority. KPI-03 was assessed on 11 indicators. The audit found compliance with {reportData.kpi_3?.compliant} indicators and non compliance with {11 - reportData.kpi_3?.compliant} indicators. </p>
            </div>
            }

            {Object.keys(reportData.kpi_9).length > 0&&
            <div className='kpi-audit'>
                <p>(vi) <b>KPI-09:</b> State performance on the ICAO safety: {orgName} is audited by ICAO under the CM-USOAP. The last audit was in {new Date().getFullYear()} and {orgName} has scored {(reportData.kpi_9?.compliant / reportData.kpi_9?.total * 100).toFixed(0)}%, well above the African average of YYY%.  </p>
            </div>
            }

            {Object.keys(reportData.kpi_10).length > 0 &&
            <div className='kpi-audit'>
                <p>(vii) <b>KPI-10:</b> security audit programs: {orgName} is audited by ICAO under the USAP. The last audit was in 20XX and {orgName} has no major Critical Security Concerns, with an average score of {(reportData.kpi_10?.compliant / reportData.kpi_10?.total * 100).toFixed(0)}% on the 8 critical elements of the security audit.</p>
            </div>
            }

            {Object.keys(reportData.kpi_16).length > 0 &&
            <div className='kpi-audit'>
                <p>(viii) <b>KPI-16:</b> The Republic of {orgName} has a process and procedure to systematically monitor and evaluate the liberalisation of air transport services and the benefits of SAATM. The State {reportData.kpi_16?.regulation ? "has" :"has not"} regulations concerning the collection and maintenance of vital air transport related statistics. The following Statistics were collected and maintained by the CCAA (delete items not collected): </p>
                <ul className='checked'>
                    {reportData.kpi_16.collected.map(((item, index) => (
                        <li key={index}>{item.split(" ").slice(7).join(" ")}</li>
                    )))}
                </ul>
                <p>The following Statistics are not collected or maintained by the CCAA (delete items collected) </p>
                <ul className='unchecked'>
                    {reportData.kpi_16.nonCollected.map(((item, index) => (
                        <li key={index}>{item.split(" ").slice(7).join(" ")}</li>
                    )))}
                </ul>
            </div>
            }

            <div style={{ heigth: "500px", marginBottom: "30px" }} className='mt-3 mb-4'>
                {data && <StateSummaryItem data={data} layout="vertical" />}
            </div>

            <div className='footer mt-4'>
                <h4 style={{ marginBottom: "20px" }} className='text-primary mb-3 mt-4'>Conclusion and recommendations</h4>
                {(Object.keys(reportData.kpi_1).length > 0 && Object.keys(reportData.kpi_2).length) > 0 &&
                <>
                    <p>The current audit assessed six critical KPIs. {orgName} has {(reportData.kpi_1.compliant / reportData.kpi_1.totalSps * 100).toFixed(2)}% compliance with KPI-1,  {(reportData.kpi_2?.fullCompliants / 34).toFixed(2)}% with KPI-2, etc. Full implementation of YD cannot (or can) be achieved with the significant gaps are found in KPI-xx, KPI-xxx. It is recommended that a corrective action plan is established with the aim of closing the identified gaps. </p>
                </>}
            </div>

            {/* <div className='footer'>
                <h4 className='text-primary mb-3 mt-2'>Conclusion and recommendations</h4>
                <p>The current audit assessed six critical KPIs. {orgName} has {(reportData.kpi_1.compliant / reportData.kpi_1.totalSps * 100).toFixed(2)}% compliance with KPI-1, XXX % with KPI-2, etc. Full implementation of YD cannot (or can) be achieved with the significant gaps are found in KPI-xx, KPI-xxx. It is recommended that a corrective action plan is established with the aim of closing the identified gaps. </p>
            </div> */}
        </div>
    )
});

export default ShortReport

