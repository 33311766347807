export const types = {

	START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',

	ADMIN_GET_STATES_REQUEST: 'ADMIN_GET_STATES_REQUEST',
    ADMIN_GET_STATES_SUCCESS: 'ADMIN_GET_STATES_SUCCESS',
    ADMIN_GET_STATES_FAILURE: 'ADMIN_GET_STATES_FAILURE',

	ADMIN_GET_USERS_REQUEST: 'ADMIN_GET_USERS_REQUEST',
    ADMIN_GET_USERS_SUCCESS: 'ADMIN_GET_USERS_SUCCESS',
    ADMIN_GET_USERS_FAILURE: 'ADMIN_GET_USERS_FAILURE',

	ADMIN_CREATE_USER_ACCOUNT_REQUEST: 'ADMIN_CREATE_USER_ACCOUNT_REQUEST',
    ADMIN_CREATE_USER_ACCOUNT_SUCCESS: 'ADMIN_CREATE_USER_ACCOUNT_SUCCESS',
    ADMIN_CREATE_USER_ACCOUNT_FAILURE: 'ADMIN_CREATE_USER_ACCOUNT_FAILURE',

	ADMIN_RESET_USER_ACCOUNT_REQUEST: 'ADMIN_RESET_USER_ACCOUNT_REQUEST',
    ADMIN_RESET_USER_ACCOUNT_SUCCESS: 'ADMIN_RESET_USER_ACCOUNT_SUCCESS',
    ADMIN_RESET_USER_ACCOUNT_FAILURE: 'ADMIN_RESET_USER_ACCOUNT_FAILURE',

	ADMIN_LIST_COMMENTS_REQUEST: 'ADMIN_LIST_COMMENTS_REQUEST',
    ADMIN_LIST_COMMENTS_SUCCESS: 'ADMIN_LIST_COMMENTS_SUCCESS',
    ADMIN_LIST_COMMENTS_FAILURE: 'ADMIN_LIST_COMMENTS_FAILURE',

    RESTORE_RESPONSE_STATUS: 'RESTORE_RESPONSE_STATUS',

    SET_CONNECTED_USERS: 'SET_CONNECTED_USERS',
};
