import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import {connect} from 'react-redux'
import PrivateRoute from './private.route';
import NormalRoute from './normal.route';
import Footer from '../../app/components/footer/footer'
import Loader from '../../app/components/loader/loader';
import ShortReport from '../../applications/report/components/short.report.template/short.report.template';

const HomeScreen = React.lazy(() => import('../screens/home/home.screen'));
const AuthScreen = React.lazy(() => import('../../applications/auth/screens/auth.screen/auth.screen'));
const Registration = React.lazy(() => import('../../applications/auth/screens/auth.screen/registration'));
const SaatmDashboard = React.lazy(() => import('../../applications/saatmDashboard/screens/saatmDashboard'));
const YDSystemDashboard = React.lazy(() => import('../../applications/ydMonitoring/screens/ydMonitoringDashboard'));
const AdminDashboard = React.lazy(() => import('../../applications/adminDashboard/screens/adminDashboard/adminDashboard'));
const Profile = React.lazy(() => import('../../applications/auth/screens/profile.screen/profile.screen'));
const Help = React.lazy(() => import('../../applications/help/screens/help'));
const ELibrary = React.lazy(() => import('../../applications/ELibrary/screens/eLibrary'));
const Definitions = React.lazy(() => import('../../applications/ELibrary/screens/definitions'));
const Reports = React.lazy(() => import('../../applications/report/screens/report'));
const Comments = React.lazy(() => import('../components/add.comment/add.comment'));
const Contact = React.lazy(() => import('../../applications/help/screens/contact'));
const Documents = React.lazy(() => import('../../applications/Documents/screens/documents'));



/**
 * @description this is the main routes for the main application src/app. 
 */
const Routes = ({loading_current_user}) => {

    if (loading_current_user) return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column'}}>
            <Loader />
            <p>Loading...</p>
        </div>
    )

    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <PrivateRoute path={"/yd-monitoring"}>
                    <Route 
                        exact 
                        component={YDSystemDashboard}
                        path={"/yd-monitoring"} />
                    <Footer />
                </PrivateRoute>
                
                <PrivateRoute path={"/saatm-dashboard"}>
                    <Route 
                        exact 
                        component={SaatmDashboard}
                        path={"/saatm-dashboard"} />
                </PrivateRoute>
                
                <PrivateRoute path={"/profile"}>
                    <Route 
                        exact 
                        component={Profile}
                        path={"/profile"} />
                </PrivateRoute>
                
                <PrivateRoute path={"/administration"}>
                    <Route 
                        exact 
                        component={AdminDashboard}
                        path={"/administration"} />
                </PrivateRoute>

                <PrivateRoute path={"/help"}>
                    <Route 
                        exact 
                        component={Help}
                        path={"/help"} />
                </PrivateRoute>

                <PrivateRoute path={"/e-library"}>
                    <Route 
                        exact 
                        component={ELibrary}
                        path={"/e-library"} />
                </PrivateRoute>

                <PrivateRoute path={"/definitions"}>
                    <Route 
                        exact 
                        component={Definitions}
                        path={"/definitions"} />
                </PrivateRoute>

                <PrivateRoute path={"/documents"}>
                    <Route 
                        exact 
                        component={Documents}
                        path={"/documents"} />
                </PrivateRoute>

                <PrivateRoute path={"/reports"}>
                    <Route 
                        exact 
                        component={Reports}
                        path={"/reports"} />
                </PrivateRoute>

                <PrivateRoute exact path={"/short-reports"}>
                    <Route 
                        exact 
                        component={ShortReport}
                        path={"/short-reports"} />
                </PrivateRoute>

                <PrivateRoute path={"/comments"}>
                    <Route 
                        exact 
                        component={Comments}
                        path={"/comments"} />
                </PrivateRoute>

                <PrivateRoute path={"/contact"}>
                    <Route 
                        exact 
                        component={Contact}
                        path={"/contact"} />
                </PrivateRoute>
                
                <NormalRoute>
                    <Route 
                        exact
                        component={<></>}
                        path={'/'} />
                    <Route 
                        exact
                        component={AuthScreen}
                        path={'/auth'} />
                    <Route 
                        exact
                        component={Registration}
                        path={'/register'} />
                </NormalRoute>
            </Switch>
        </Suspense>)
}

const mapStateToProps = ({AuthReducer}) =>({
    loading_current_user: AuthReducer.loading_current_user
});

export default connect(mapStateToProps)(Routes);
