export const types = {

    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',

    GET_STATES_REQUEST: 'GET_STATES_REQUEST',
    GET_STATES_SUCCESS: 'GET_STATES_SUCCESS',
    GET_STATES_FAILURE: 'GET_STATES_REQUEST',

    GET_KPI_DATA_REQUEST: 'GET_KPI_DATA_REQUEST',
    GET_KPI_DATA_SUCCESS: 'GET_KPI_DATA_SUCCESS',
    GET_KPI_DATA_FAILURE: 'GET_KPI_DATA_FAILURE',

    GET_KPI_DATA_SUMMARY_REQUEST: 'GET_KPI_DATA_SUMMARY_REQUEST',
    GET_KPI_DATA_SUMMARY_SUCCESS: 'GET_KPI_DATA_SUMMARY_SUCCESS',
    GET_KPI_DATA_SUMMARY_FAILURE: 'GET_KPI_DATA_SUMMARY_FAILURE',

    GET_KPIS_REQUEST: 'GET_KPIS_REQUEST',
    GET_KPIS_SUCCESS: 'GET_KPIS_SUCCESS',
    GET_KPIS_FAILURE: 'GET_KPIS_FAILURE',

    SET_KPI_REQUEST: 'SET_KPI_REQUEST',

    SET_STATE_REQUEST: 'SET_STATE_REQUEST',

    SET_ORG_REQUEST: 'SET_ORG_REQUEST',
}