import { types } from "./types";

const INITIAL_STATE = {
    documents: [],
    loading: false,
    error: null,
    uploading: false,
    document: null,
    success: false
}

const DocumentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case types.UPLOAD_DOCUMENTS_REQUEST:
            return {
                ...state,
                uploading: true,
                document: null,
                success: false
            };

        case types.UPLOAD_DOCUMENTS_SUCCESS:
            return {
                ...state,
                document: action.payload,
                documents: [action.payload.data, ...state.documents],
                uploading: false,
                success: true
            };

        case types.UPLOAD_DOCUMENTS_FAILURE:
            return {
                ...state,
                error: action.payload,
                uploading: false,
                success: false
            };
        
        case types.GET_STATE_DOCUMENTS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case types.GET_STATE_DOCUMENTS_SUCCESS:
            return {
                ...state,
                documents: action.payload.data,
                loading: false,
                error: null
            };

        case types.GET_STATE_DOCUMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
}

export default DocumentReducer;