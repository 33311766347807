import {put, takeLatest, select} from 'redux-saga/effects'
import {getRequest, postRequest, putRequest} from '../../../../helpers/api'
import { types } from "../reducer/types";
import {config} from '../../../../helpers/constants'
import { notification } from 'antd';

const API_URL = `${config.API_URL}/api`

/**
 * @description get states
 */
function* getStates () {
    yield put({type: types.START_LOADING, payload: types.GET_STATES_REQUEST})
    try { 
        const {data} = yield getRequest(`${API_URL}/states`);
        yield put({type: types.GET_STATES_SUCCESS, payload: data})
    } catch (error) {
        yield put({type: types.GET_STATES_FAILURE, payload: error});
    } finally {
        yield put({type: types.STOP_LOADING, payload: types.GET_STATES_REQUEST})
    }
}

/**
 * @description get questionnaire
 */
function* getQuestionnaire () {
    yield put({type: types.START_LOADING, payload: types.GET_QUESTIONNAIRE_REQUEST})
    try { 
        const {data} = yield getRequest(`${API_URL}/questionnaire`);
        yield put({type: types.GET_QUESTIONNAIRE_SUCCESS, payload: data})
    } catch (error) {
        yield put({type: types.GET_QUESTIONNAIRE_FAILURE, payload: error});
    } finally {
        yield put({type: types.STOP_LOADING, payload: types.GET_QUESTIONNAIRE_REQUEST})
    }
}

/**
 * @description get organisation responses
 */
function* getOrgResponses ({payload}) {
    const {orgId, load} = payload
    yield put({type: types.START_LOADING, payload: types.GET_ORG_RESPONSES_REQUEST})
    try { 
        const {data} = yield getRequest(`${API_URL}/response/${orgId}`);
        yield put({type: types.GET_ORG_RESPONSES_SUCCESS, payload: data})
    } catch (error) {
        yield put({type: types.GET_ORG_RESPONSES_FAILURE, payload: error});
    } finally {
        yield put({type: types.STOP_LOADING, payload: types.GET_ORG_RESPONSES_REQUEST})
    }
}


/**
 * @description get questionnaire
 */
 function* getSPsAndResponses ({payload}) {
     const {orgId, load} = payload
     yield put({type: types.START_LOADING, payload: types.GET_SPS_AND_RESPONSES_REQUEST})
    try { 
        const {data} = yield getRequest(`${API_URL}/response/questions/${orgId}`);
        yield put({type: types.GET_SPS_AND_RESPONSES_SUCCESS, payload: data})
    } catch (error) {
        yield put({type: types.GET_SPS_AND_RESPONSES_FAILURE, payload: error});
    } finally {
        yield put({type: types.STOP_LOADING, payload: types.GET_SPS_AND_RESPONSES_REQUEST})
    }
}

/**
 * @description get organisations
 */
function* getOrganisations () {
    yield put({type: types.START_LOADING, payload: types.GET_ORGANISATIONS_REQUEST})
    try { 
        const {data} = yield getRequest(`${API_URL}/organisations`);
        yield put({type: types.GET_ORGANISATIONS_SUCCESS, payload: data})
    } catch (error) {
        yield put({type: types.GET_ORGANISATIONS_FAILURE, payload: error});
    } finally {
        yield put({type: types.STOP_LOADING, payload: types.GET_ORGANISATIONS_REQUEST})
    }
}

/**
 * @description save response
 */
function* saveResponse ({payload}) {
    yield put({type: types.START_LOADING, payload: types.SAVE_RESPONSE_REQUEST})
    try { 
        const responses = payload.responses;
        const promises = []
        responses.forEach(reponse => {
            promises.push(postRequest(`${API_URL}/response`, reponse))
        });
        if(promises.length) {
            const responses = yield Promise.all(promises);
            
            if(responses.length) {
                //  Update spAndResponses datas
                const { spsAndResponses } = yield select(({ YDMonitoringReducer }) => ({
                    spsAndResponses: YDMonitoringReducer.spsAndResponses,
                }));
                
                // Update orgResponses datas
                const { orgResponses } = yield select(({ YDMonitoringReducer }) => ({
                    orgResponses: YDMonitoringReducer.orgResponses,
                }));
    
                let updatedResponses = orgResponses.map(response => {
                    const item = responses.find(res => res.YDMS_Org_id === response.organisationYDMSOrgId && 
                        res.YDMS_SP_id === response.organisationYDMSOrgId)
                    if(item) {
                        response.questionnaire_response = item.response
                    }
                    return response;
                })   

                const updatedSPandResponses = spsAndResponses.survey_protocols.map(response => {
                    const item = responses.find(res => res.YDMS_Org_id === response.sp_response.organisationYDMSOrgId && 
                        res.YDMS_SP_id === response.sp_response.surveyProtocolYDMSSPId)
                    if(item) {
                        response.sp_response.questionnaire_response = item.response
                    }
                    return response;
                })

                // Update orgResponses datas
                const { states } = yield select(({ YDMonitoringReducer }) => ({
                    states: YDMonitoringReducer.states,
                }));

                let updatedStates = states;

                // const orgId = payload[0].YDMS_Org_id

                // If It is SP of KPI_1 update the this.state
                const kpi1Response = responses.find(res => res.kpi === 'kpi_1')

                if(kpi1Response) {
                    updatedStates = states.map(state => {
                        if(kpi1Response.YDMS_Org_id === state.YDMS_AU_id) {
                            state.SAATM_membership = !!kpi1Response.response
                        }
                        return state
                    })

                    yield put({type: types.UPDATE_STATE_REQUEST, payload: {stateId: kpi1Response.YDMS_Org_id, SAATM_membership: !!kpi1Response.response}})

                }

                yield put({type: types.SAVE_RESPONSE_SUCCESS, payload: {
                    responses, spsAndResponses: {...spsAndResponses, survey_protocols: updatedSPandResponses}, 
                    orgResponses: updatedResponses, states: updatedStates}
                })

                if(payload.reloadPage) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }

                // Fetch new responses
                // yield put({type: types.GET_ORG_RESPONSES_REQUEST, payload: {orgId, load: false}})
                // yield put({type: types.GET_SPS_AND_RESPONSES_REQUEST, payload: { orgId, load: false }})

            }


        } else {
            yield put({type: types.SAVE_RESPONSE_SUCCESS, payload: {}})
        }
    } catch (error) {
        yield put({type: types.SAVE_RESPONSE_FAILURE, payload: error});
    } finally {
        yield put({type: types.STOP_LOADING, payload: types.SAVE_RESPONSE_REQUEST})
    }
}

/**
 * @description bulk save response
 */
 function* bulksaveResponse ({payload}) {
    yield put({type: types.START_LOADING, payload: types.BULK_SAVE_RESPONSE_REQUEST})
    try { 
        const { data } = yield postRequest(`${API_URL}/response/bulk`, { responses: payload.responses });

        if(data) {
            yield put({type: types.BULK_SAVE_RESPONSE_SUCCESS, payload: {}})
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    } catch (error) {
        console.log({ error })
        if(error.response?.status === 503 || !error.response) {
            yield put({type: types.BULK_SAVE_RESPONSE_SUCCESS, payload: {}})
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            yield put({type: types.BULK_SAVE_RESPONSE_FAILURE, payload: error});
        }
    } finally {
        yield put({type: types.STOP_LOADING, payload: types.BULK_SAVE_RESPONSE_REQUEST})
    }
}

/**
 * @description get provision
 */
 function* getProvision ({payload}) {
     const {instId, provisionNumber} = payload
    yield put({type: types.START_LOADING, payload: types.GET_PROVISION_REQUEST})
    try { 
        const {data} = yield getRequest(`${API_URL}/libraries/provision/${instId}/${provisionNumber}`);
        yield put({type: types.GET_PROVISION_SUCCESS, payload: data})
    } catch (error) {
        yield put({type: types.GET_PROVISION_FAILURE, payload: error});
    } finally {
        yield put({type: types.STOP_LOADING, payload: types.GET_PROVISION_REQUEST})
    }
}

/**
 * @description Update state
 * @param {{stateId: string, SAATM_membership: 0 | 1}} payload
 */
 function* updateState ({payload}) {
    const { stateId, SAATM_membership } = payload
   yield put({type: types.START_LOADING, payload: types.UPDATE_STATE_REQUEST})
   try { 
        const {data} = yield putRequest(`${API_URL}/states/${stateId}`, { SAATM_membership });
        yield put({type: types.UPDATE_STATE_SUCCESS, payload: data})
   } catch (error) {
       yield put({type: types.UPDATE_STATE_FAILURE, payload: error});
   } finally {
       yield put({type: types.STOP_LOADING, payload: types.UPDATE_STATE_REQUEST})
   }
}

export default function* YDMonitoringSaga() {
    yield takeLatest(types.GET_STATES_REQUEST, getStates);
    yield takeLatest(types.GET_QUESTIONNAIRE_REQUEST, getQuestionnaire);
    yield takeLatest(types.GET_ORG_RESPONSES_REQUEST, getOrgResponses);
    yield takeLatest(types.GET_SPS_AND_RESPONSES_REQUEST, getSPsAndResponses);
    yield takeLatest(types.SAVE_RESPONSE_REQUEST, saveResponse);
    yield takeLatest(types.GET_ORGANISATIONS_REQUEST, getOrganisations);
    yield takeLatest(types.GET_PROVISION_REQUEST, getProvision);
    yield takeLatest(types.UPDATE_STATE_REQUEST, updateState);
    yield takeLatest(types.BULK_SAVE_RESPONSE_REQUEST, bulksaveResponse);
}