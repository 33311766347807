import React from 'react'
import { connect } from 'react-redux'
import './footer.scss'

/**
 * @description empty data
 */

const Footer = ({ user }) => {
    
    return (
        <div id="footer">
            <div className="content">
                <p>CAA Address: <b>{user.CAA_address ? user.CAA_address?.address_line : 'Address goes here'}</b></p>
                <p>Focal Person: <b>{user.focalPerson ? user.focalPerson : 'Name here'}</b></p>
                <p>Email: <b>{user.focalPersonEmail ? user.focalPersonEmail : 'email@email.com'}</b></p>
            </div>
        </div>
    )
}

const mapStateToProps = ({ AuthReducer }) => ({
    user: AuthReducer.user,
})

export default connect(mapStateToProps)(Footer);

