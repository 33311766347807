import {call, put, takeLatest, delay} from 'redux-saga/effects';
import { types } from "../reducer/types";
import {notification} from 'antd';
import authClass from "./auth.class";
import { types as saatmTypes } from "../../../saatmDashboard/redux/reducer/types"

/**
 * @description user sign in.  
 */
function* authSignIn ({payload}) 
{
    try {
        const {data} = yield call(authClass.authSignIn, payload);
        yield put({ type: types.SIGN_IN_USER_SUCCESS, payload: data });
        localStorage.setItem('token', JSON.stringify(data.accessToken));
        localStorage.setItem('orgId', JSON.stringify(data.orgId));
        localStorage.setItem('user', JSON.stringify(data));
        yield put({ type: types.REDIRECT_REQUEST, payload: payload.redirect });
    } 
    catch (error)
    {
        yield put({ type: types.SIGN_IN_USER_FAILURE, payload: error.response?.data || error.message });
    }
}

/**
 * @description user sign in.  
 */
function* authRegister ({payload}) 
{
    try {
        const {data} = yield call(authClass.authRegister, payload);
        
        notification['success']({
            message: 'Authentication Success',
            description: 'Your account has been successfully created. Your password have been sent to your email.',
        });
        
        yield delay(2000)
        yield put({ type: types.REGISTER_USER_SUCCESS, payload: data });

        // yield authSignIn({payload: {email: payload.email, password: payload.password, redirect: payload.redirect}})
    } 
    catch (error)
    {
        yield put({ type: types.REGISTER_USER_FAILURE, payload: error.response?.data || error.message });
    }
}

/**
 * @description update user profile.  
 */
function* updateUser ({payload}) {
    try {
        const {data} = yield call(authClass.updateUser, payload);
        yield put({ type: types.UPDATE_USER_SUCCESS, payload: data });
    } 
    catch (error)
    {
        yield put({ type: types.UPDATE_USER_FAILURE, payload: error.response.data });
    }
}


/**
 * @description check the current autheticated user.   
 */
function* authCurrentAuthenticatedUser () 
{
    try {
        // Call api to get user profile infos
        const user = JSON.parse(localStorage.getItem('user'));
        if(user) {
            const { data } = yield call(authClass.profileInfo)
            yield put({ type: types.CURRENT_AUTHENTICATED_USER_SUCCESS, payload: data });
            yield put({ type: saatmTypes.GET_STATES_REQUEST });
        } else {
            yield put({ type: types.CURRENT_AUTHENTICATED_USER_FAILURE, payload: null });
        }
    } 
    catch (error)
    { 
        yield put({ type: types.CURRENT_AUTHENTICATED_USER_FAILURE, payload: error });
        // yield put({type: types.LOGOUT_USER_REQUEST});
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.clear();
    }
}



/**
 * @description user logout.  
 */
function* authLogout ({payload}) 
{
    try {
        window.location.replace('/');
        localStorage.clear();
        yield put({type: types.LOGOUT_USER_SUCCESS});
    } 
    catch (error)
    {
        const {message} = error;
        yield put({ type: types.LOGOUT_USER_FAILURE, payload: error });
    }
}

/**
 * @description submit commet
 */
function* submitComment ({payload}) {
    try {
        const {data} = yield call(authClass.submitComment, payload);
        yield put({ type: types.SUBMIT_COMMENT_SUCCESS, payload: data });
    } 
    catch (error)
    {
        yield put({ type: types.SUBMIT_COMMENT_FAILURE, payload: error.response.data });
    }
}

export default function* AuthSaga() 
{
    yield takeLatest(types.CURRENT_AUTHENTICATED_USER_REQUEST, authCurrentAuthenticatedUser);
    yield takeLatest(types.SIGN_IN_USER_REQUEST, authSignIn);
    yield takeLatest(types.REGISTER_USER_REQUEST, authRegister);
    yield takeLatest(types.LOGOUT_USER_REQUEST, authLogout);
    yield takeLatest(types.UPDATE_USER_REQUEST, updateUser);
    yield takeLatest(types.SUBMIT_COMMENT_REQUEST, submitComment);
}